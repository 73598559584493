<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  components: {
  },
  props: {},
  data() {
    return {
    }
  },
  computed: {},
  methods: {
    ...mapActions(['adminLogin'])
  },
  created() { },
  mounted() {
    this.adminLogin()
  }
}
</script>
<style scoped lang="less">
#app {
  background: rgba(241, 244, 246, 1);
  display: flex;
  flex-direction: column;
  height: 100;
  margin: 0;
}

/deep/.el-picker-panel {
  z-index: 9999 !important;
}
</style>
