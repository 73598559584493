// axios.js

import { clearCookie } from '@/utils/cookies'
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_BASE_API  // 正式
// axios.defaults.baseURL = 'https://adminapi.z-srm.com' // 测试服(旧项目)
// 请求拦截器
axios.interceptors.request.use(res => {
  res.headers['satoken'] = window.localStorage.getItem('token')
  return res
})
axios.interceptors.response.use(res => {
  // if (res.data.code === 403) {
  //   window.location.href = `${process.env.VUE_APP_BASE_URL}login`
  // }
  if (res.data.code === 403) {
    window.localStorage.clear()
    clearCookie(['frontToken', 'adminToken'])
    // window.location.href = `${process.env.VUE_APP_BASE_URL}login`
  }
  return res
})

export const loginOutApi = params => {
  return axios.get('/FOR_/login/loginOut')
}
// 登录
export const loginApi = params => {
  return axios.get('/FOR_/login/phonePswLogin?phone=' + params.phone + '&psw=' + params.psw)
}
// 获取用户信息
// export const  userInfoApi= params => {
//   return axios.get('/FOR_/login/userInfo')
// }
export const userInfoApi = params => {
  return axios.get('FOR_/user/userInfo')
}

// 产品询价
export const enquiryListApi = params => {
  return axios.get('/foreground/enquiryOffer/list?addressProvinceId=' + params.addressProvinceId + '&addressCityId=' + params.addressCityId + '&addressAreaId=' + params.addressAreaId + '&current=' + params.current + '&size=' + params.size + '&round=' + params.round + '&timeType=' + params.timeType + '&beginTime=' + params.beginTime + '&endTime=' + params.endTime + '&type=' + params.type + '&state=' + params.state)
}

// 产品询价报价
export const offerPriceApi = params => {
  return axios.post('/foreground/enquiryOffer/submit', params)
}
// 极速询价
export const addEnquiryApi = params => {
  return axios.post('/new/topSpeed/insert', params)
}

// 查看自己报价
export const myOfferPriceApi = params => {
  return axios.get('/foreground/enquiry/offerDetail?enquiryLineId=' + params) 
}

// 供求企业列表
export const companyListApi = params => {
  return axios.get('/FOR_/userCompany/page?current=' + params.current + '&code=' + params.code + '&provinceCode=' + params.provinceCode + '&districtCode=' + params.districtCode + '&cityCode=' + params.cityCode + '&size=' + params.size + '&name=' + params.name + '&verificationMode=' + params.verificationMode)
}
export const companyInfoApi = params => {
  return axios.get('/FOR_/userCompany/info?id=' + params)
}
// 获取全部地址
export const addressApi = params => {
  return axios.get('/common/findAllArea')
}
// 询价热门推荐
export const enquiryHotListApi = params => {
  return axios.get('/foreground/enquiryOffer/hotList?current=' + params.current + '&size=' + params.size + '&type=' + params.type)
}
// 询价详情
export const enquiryDetailListApi = params => {
  return axios.get('/foreground/enquiryOffer/info?id=' + params.id + '&proId=' + params.proId)
}
// 首页全部分类数据
export const classificationsListApi = params => {
  return axios.get('/gat/industry/tree?type=' + params)
}

// 获取热门询价★
export const hotShopListApi = params => {
  return axios.get('/foreground/enquiryOffer/hotProList?current=' + params.current + '&size=' + params.size)
}
// 获取询价商品列表★
export const askPriceShopListApi = params => {
  return axios.get('/foreground/enquiryOffer/getProList?name=' + params.name + '&proName=' + params.proName + '&addressProvinceId=' + params.addressProvinceId + '&addressCityId=' + params.addressCityId + '&addressAreaId=' + params.addressAreaId + '&current=' + params.current + '&size=' + params.size + '&round=1' + '&timeType=' + params.timeType + '&beginTime=' + params.beginTime + '&endTime=' + params.endTime + '&type=' + params.type + '&industryId=' + params.industryId)
}
// 获取竞价采购列表★
export const biddingListApi = params => {
  return axios.get('/foreground/enquiryOffer/list?name=' + params.name + '&proName=' + params.proName + '&addressProvinceId=' + params.addressProvinceId + '&addressCityId=' + params.addressCityId + '&addressAreaId=' + params.addressAreaId + '&current=' + params.current + '&size=' + params.size + '&round=' + params.round + '&timeType=' + params.timeType + '&beginTime=' + params.beginTime + '&endTime=' + params.endTime + '&type=' + params.type + '&state=' + params.state)
}
// 获取拍卖单列表★
export const auctionListApi = params => {
  return axios.get('/foreground/auction/getProList?current=' + params.current + '&size=' + params.size + '&isHot=' + params.isHot)
}

// 拍卖单列表
export const auctionList2Api = params => {
  return axios.get('/foreground/auction/getProList?current=' + params.current + '&size=' + params.size + '&isHot=' + params.isHot + '&addressProvinceId=' + params.addressProvinceId + '&addressCityId=' + params.addressCityId + '&addressAreaId=' + params.addressAreaId + '&createTime=' + params.createTime + '&endTime=' + params.endTime + '&timeType=' + params.timeType )
}

// 拍卖单详情
export const auctionDetailApi = params => {
  return axios.get('/foreground/auction/proDetail?id=' + params.id + '&proId=' + params.proId)
}

// 拍卖单报价
export const offerAuctionPriceApi = params => {
  return axios.post('/foreground/auction/submit', params)
}

// 新闻分类tab列表
export const pressTypeListApi = params => {
  return axios.get('/gat/new/typeList')
}
// 新闻数据列表
export const pressListApi = params => {
  return axios.get('/gat/new/page?current=' + params.current + '&size=' + params.size + '&typeId=' + params.typeId + '&recSite=' + params.recSite + '&tag=' + params.tag)
}
export const pressInfoApi = params => {
  return axios.get('/gat/new/info?id=' + params)
}
// 获取登录
export const codeLoginApi = params => {
  return axios.get('/FOR_/login/phoneCodeLogin?phone=' + params.phone + '&code=' + params.code)
}

// 校验手机号是否注册
export const checkPhoneApi = params => {
  return axios.get('/FOR_/login/checkPhone?phone=' + params.phone)
}

// 校验验证码是否正确
export const checkCodeApi = params => {
  return axios.post('/FOR_/login/changeCode?phone=' + params.phone + '&code=' + params.code)
}

// 修改密码
export const editPswApi = params => {
  return axios.post('/FOR_/login/changePsw?phone=' + params.phone + '&code=' + params.code + '&psw=' + params.psw)
}

// 发送验证码
export const sendCodeApi = params => {
  return axios.get('/FOR_/login/sendMessage?phone=' + params.phone + '&type=' + params.type)
}

// 获取登录
export const registerApi = params => {
  return axios.post('/FOR_/login/sign', params)
}

// 获取昨日新增采购
export const addHotShopListApi = params => {
  return axios.get('/foreground/enquiry/listIndexNumber?current=' + params.current + '&size=' + params.size + '&type=' + params.type)
}

// 询价竞价采购列表筛选统计
export const askPriceNumberApi = params => {
  return axios.get('/foreground/enquiry/btnStat?type=' + params)
}

// 供应端报价单筛选统计
export const sellAskPriceNumberApi = params => {
  return axios.get('/foreground/enquiry/offerStat?type=' + params)
}

// 获取所有省市区分类
export const getProvinceAllApi = params => {
  return axios.get('/common/findAllArea')
}

// 收货地址
export const addressListApi = params => {
  return axios.get('/MID_/companyAddress/page?current=' + params.current + '&size=' + params.size + '&provinceCode=' + params.provinceCode + '&cityCode=' + params.cityCode + '&areaCode=' + params.areaCode + '&companyId=' + params.companyId + '&otherCompanyName=' + params.otherCompanyName + '&name=' + params.name + '&phone=' + params.phone)
}

// 新增收货地址
export const addAddressApi = params => {
  return axios.post('/MID_/companyAddress/add', params)
}
