import { userInfoApi } from '@/api'
import { getCookie } from '@/utils/cookies'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null, // 用户信息，可以是一个对象
    redirectRoute: null, // 存储重定向的路由路径
    classifyBox: true // 展示头部分类
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    setRedirectRoute(state, route) {
      state.redirectRoute = route
    },
    clearRedirectRoute(state) {
      state.redirectRoute = null
    },
    clearUser(state) {
      state.user = null
    },
    setClassifyBox(state, value) {
      state.classifyBox = value
    }
  },
  actions: {
    loginUser({ commit }, user) {
      // 在这里执行登录逻辑，然后提交mutation来更新user状态
      commit('setUser', user)
    },
    // 后台登录
    async adminLogin({ commit, state }) {
      console.log('adminLogin')
      // 检测cookie
      const oldToken = localStorage.getItem('token')
      const frontToken = getCookie('frontToken')
      console.log(oldToken)
      console.log(frontToken)
      if (frontToken && !oldToken) {
        localStorage.setItem('token', frontToken)
        const { data: userInfo } = await userInfoApi()
        if (userInfo.code === 200) {
          localStorage.setItem('userInfo', JSON.stringify(userInfo.data))
          localStorage.setItem('token', frontToken)
          console.log(location.origin)
          if (location.pathname === '/login') {
            window.open(location.origin + '/home', '_self')
          }
        }
      }
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.user !== null // 根据用户状态判断是否已登录
    }
  }
})

