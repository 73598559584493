<template>
  <div :style="$route.path !== '/home' && showClassifyBox === true ? 'height: 136px' : 'height: 68px'" class="header">
    <!-- <div v-if="$route.path == '/home'" class="topImg">
      <img src="../assets/img/topImg.png" alt="">
      <p>降低成本、提高效率、降低采购风险、提高采购透明度</p>
    </div> -->
    <div class="headerBox">
      <div class="headerLeft">
        <img @click="routerPush(1)" src="../assets/img/logo2.png" alt="" />
        <h3 @click="routerPush(1)" style="color: rgba(0, 0, 0, 1)">炙讯云</h3>
        <ul>
          <li :style="chooseVal === 1 ? 'color: rgba(24, 144, 255, 1)' : ''" @click="routerPush(1)">平台首页</li>
          <li :style="chooseVal === 2 ? 'color: rgba(24, 144, 255, 1)' : ''" @click="routerPush(2)">产品询价</li>
          <li :style="chooseVal === 3 ? 'color: rgba(24, 144, 255, 1)' : ''" @click="routerPush(3)">产品竞价</li>
          <li :style="chooseVal === 5 ? 'color: rgba(24, 144, 255, 1)' : ''" @click="routerPush(4)">产品拍卖</li>
          <!-- <li :style="chooseVal === 4 ? 'color: rgba(24, 144, 255, 1)' : ''" @click="routerPush(5)">供求企业</li> -->
          <!-- <li :style="chooseVal === 5 ? 'color: rgba(24, 144, 255, 1)' : ''" >行业资讯</li> -->
          <!-- <li><a style="color: rgba(0, 0, 0, 0.60);" href='http://new.z-srm.com/'>SCRM管理系统</a></li> -->
        </ul>
      </div>
      <div class="headerRight">
        <el-button v-if="$route.path == '/home'" class="searchStyle" @click="toggleClassifyBox"><i class="el-icon-menu"></i>分类查询</el-button>
        <!-- <el-button v-else class="searchStyle"><i class="el-icon-menu"></i>分类查询</el-button> -->
        <div class="headerSearch">
          <el-input ref="searchInputRef" @input="recordSearch()" @keyup.enter.native="handleSearch('')"
            placeholder="请输入商品名称/竞价标题" v-model="searchInput" class="input-with-select">
            <template #append>
              <i style="cursor: pointer;" class="el-icon-search" @click="handleSearch('')"></i>
            </template>
          </el-input>
          <!-- <el-input class="searchBox"  suffix-icon="el-icon-search" placeholder="搜索" v-model="searchInput"></el-input>
          <button class="searchBtn"><span>采购<i class="el-icon-arrow-down"></i></span></button> -->
        </div>
        <!-- <el-button v-if="token == null || token == ''" @click="routerJump(1)" class="lightStyle">登录</el-button> -->
        <div v-if="token !== null && token !== ''" @click="userDialog = !userDialog" class="userBox">
          <i class="el-icon-user-solid"></i>
          <img
            :src="headerImg == null || headerImg == '' ? 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' : headerImg"
            alt="">
          <div v-if="userDialog === true" class="userDialog">
            <p @click="routerJump(3)">企业中心</p>
            <p @click="routerJump(3)">个人信息</p>
            <p @click="loginOut()">退出登录</p>
          </div>
        </div>
        <!-- <el-button v-if="token == null || token == ''" @click="routerJump(2)" class="lightDeepStyle">免费注册</el-button> -->
        <!-- <el-button v-else @click="routerJump(3)" class="lightDeepStyle"><i style="margin-right: 6px; font-size: 14px;" class="el-icon-data-line"></i>企业中心<i style="margin-left: 24px; font-size: 14px;" class="el-icon-caret-right"></i></el-button> -->
      </div>
    </div>
    <div v-if="showClassifyBox === true" class="classifyBox" @mousedown="handleMouseDown" @mouseup="handleMouseUp"
      @mousemove="handleMouseMove">
      <div @click="scrollLeft" class="leftClassify"><i class="el-icon-caret-left"></i></div>
      <div class="centerClassify" ref="centerClassify">
        <ul>
          <li ref="classifyListRef" v-for="(item, index) in classifyList" :key="index" @click="openClassifySub(item.children, index)">
            <!-- <img :src="require('../assets/img/middle/left' + index + '.png')" alt=""> -->
            <img :src="item.icon" alt="">
            <p class="textHidden">{{ item.name }}</p>
            <i class="el-icon-arrow-right"></i>
            <div v-if="chooseClassify === index" class="classifySubBox">
              <div v-if="chooseClassList.length !== 0">
                <p v-for="(val, i) in chooseClassList" :key="i">
                  <i @click="handleSearch([item.id, val.id], [item.name, val.name, '不限'])">{{ val.name }}</i>
                  <span @click="handleSearch([item.id, val.id, vals.id], [item.name, val.name, vals.name])"
                    v-for="(vals, ind) in val.children" :key="ind">{{ vals.name }}</span>
                </p>
              </div>
              <div v-else style="margin: 0;">
                <img src="../assets/img/notData.png"
                  style="width: 100px; height: 100px; margin-top: 10%; margin-left: 50%; transform: translate(-50%)">
                <span
                  style="display: block; width: 100%; text-align: center; color: rgba(0, 0, 0, .4); font-size: 14px; margin-bottom: 10%;">暂无数据</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div @click="scrollRight" class="rightClassify"><i class="el-icon-caret-right"></i></div>
    </div>
  </div>
</template>

<script>
import { loginOutApi, classificationsListApi } from "@/api/index"
export default {
  components: {},
  props: {},
  data() {
    return {
      chooseClassify: '',
      chooseClassList: '',
      isDragging: false,
      startX: 0,
      scrollLeftStart: 0,
      currentIndex: 0,
      userDialog: false,
      showClassifyBox: false,
      chooseVal: '',
      headerImg: '',
      searchInput: '',
      token: '',
      name: '',
      // 全部分类数据
      classifyList: [],
    }
  },
  created() {
    this.name = window.localStorage.getItem('userName')
    this.token = window.localStorage.getItem('token')
    this.getClassifyList()
  },
  mounted() {
    if (this.$route.path == '/home') {
      this.chooseVal = 1
      this.$store.commit('setClassifyBox', false)
    } else if (this.$route.path == '/product') {
      this.chooseVal = 2
      this.$store.commit('setClassifyBox', false)
      this.chooseClassify = ''
    } else if (this.$route.path == '/bidding') {
      this.chooseVal = 3
      this.$store.commit('setClassifyBox', false)
      this.chooseClassify = ''
    } else if (this.$route.path == '/auction') {
      this.chooseVal = 5
      this.$store.commit('setClassifyBox', false)
      this.chooseClassify = ''
    } else if (this.$route.path == '/search') {
      this.searchInput = window.sessionStorage.getItem('recordSearch')
      // 获取焦点
      this.$refs.searchInputRef.focus()
    } else {
      this.$store.commit('setClassifyBox', false)
      this.chooseClassify = ''
    }
    this.showClassifyBox = this.$store.state.classifyBox
  },
  watch: {
    classifyBox(newValue) {
      this.showClassifyBox = this.$store.state.classifyBox
    }
  },
  computed: {
    classifyBox() {
      return this.$store.state.classifyBox;
    },
    link() {
      const baseUrl = process.env.VUE_APP_BASE_URL
      const token = this.token // 替换为实际的令牌值
      return `${baseUrl}#/home?id=${token}`
    },
  },
  methods: {
    recordSearch() {
      window.sessionStorage.setItem('recordSearch', this.searchInput)
    },
    handleSearch(industry, industryName) {
      let newIndustry = ''
      let newIndustryName = ''
      if (industry !== '') {
        newIndustry = industry.join(',')
        newIndustryName = industryName.join(',')
      }
      this.$router.push({ path: 'search', query: { industry: newIndustry, industryName: newIndustryName, searchInput: this.searchInput } })
    },
    openClassifySub(item, index) {
      if (this.isDragging) {
        // 如果是拖拽操作，阻止弹框显示
        return;
      }
      if (this.chooseClassify === index) {
        this.chooseClassify = null;
      } else {
        this.chooseClassify = index;
        this.chooseClassList = item;
        this.$nextTick(() => {
          this.adjustClassifySubBox(index);
        });
      }
    },
    adjustClassifySubBox(index) {
      const liElement = this.$refs.classifyListRef[index];
      const classifySubBox = liElement.querySelector('.classifySubBox');
      const liRect = liElement.getBoundingClientRect();
      const subBoxRect = classifySubBox.getBoundingClientRect();
      console.log(liRect.left)
      if (liRect.left + subBoxRect.width > window.innerWidth) {
        classifySubBox.style.left = `${window.innerWidth - subBoxRect.width - liRect.left - 60}px`;
      } else if (liRect.left + subBoxRect.width < 400) {
        // 如果弹出框超出左边界
        classifySubBox.style.left = `${Math.abs(liRect.left - 60)}px`;
      } else {
        classifySubBox.style.left = '0';
      }
    },
    handleMouseDown(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.scrollLeftStart = this.$refs.centerClassify.scrollLeft;
      // 禁用鼠标事件的默认行为，避免冲突
      event.preventDefault();
    },
    handleMouseMove(event) {
      if (this.isDragging) {
        const deltaX = event.clientX - this.startX;
        this.$refs.centerClassify.scrollLeft = this.scrollLeftStart - deltaX;
        // 动态调整弹框位置
        if (this.chooseClassify !== null) {
          this.adjustClassifySubBox(this.chooseClassify);
        }
      }
    },
    handleMouseUp() {
      this.isDragging = false;
    },
    scrollLeft() {
      this.isDragging = false; // 确保拖拽和点击滑动不冲突
      const scrollAmount = this.$refs.centerClassify.clientWidth / 2; // 滑动一半的宽度
      this.smoothScroll(this.$refs.centerClassify.scrollLeft - scrollAmount);
    },
    scrollRight() {
      this.isDragging = false; // 确保拖拽和点击滑动不冲突
      const scrollAmount = this.$refs.centerClassify.clientWidth / 2; // 滑动一半的宽度
      this.smoothScroll(this.$refs.centerClassify.scrollLeft + scrollAmount);
    },
    smoothScroll(target) {
      // 使用 requestAnimationFrame 实现平滑滚动
      const start = this.$refs.centerClassify.scrollLeft;
      const change = target - start;
      const duration = 300;
      let currentTime = 0;
      const animateScroll = () => {
        currentTime += 20;
        const val = this.easeInOutQuad(currentTime, start, change, duration);
        this.$refs.centerClassify.scrollLeft = val;
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        }
      };
      animateScroll();
    },
    easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    },
    // 获取行业分类全部数据
    async getClassifyList() {
      if (window.sessionStorage.getItem('classifyList') == undefined) {
        await classificationsListApi(0).then((res) => {
          if (res.status === 200) {
            this.classifyList = res.data.data.records
            window.sessionStorage.setItem('classifyList', JSON.stringify(this.classifyList))
          }
        })
      } else {
        this.classifyList = JSON.parse(window.sessionStorage.getItem('classifyList'))
      }
    },
    // 分类显示隐藏
    toggleClassifyBox() {
      this.showClassifyBox = !this.showClassifyBox
    },
    routerPush(val) {
      this.chooseVal = val
      this.showClassifyBox = false
      this.chooseClassify = ''
      if (val == 1) {
        this.$router.push('/home')
      } else if (val == 2) {
        this.$router.push({ path: '/product' })
      } else if (val == 3) {
        this.$router.push({ path: '/bidding' })
      } else if (val == 4) {
        this.$router.push({ path: '/auction' })
      } else if (val == 5) {
        this.$router.push({ path: '/company' })
      }
    },
    routerJump(val) {
      if (val == 1) {
        this.$router.push({ path: '/login' })
      } else if (val == 2) {
        this.$router.push({ path: '/login', query: { type: val } })
      } else if (val == 3) {
        if (this.token == null || this.token == '') {
          this.$message.error('请登录后再前往企业中心!')
        } else {
          // 获取companyStatus
          const baseUrl = JSON.parse(window.localStorage.getItem('userInfo')??{})?.companyStatus === '1' ? process.env.VUE_APP_REDIRECT_URL : process.env.VUE_APP_BASE_URL
          const token = this.token // 替换为实际的令牌值
          const link = `${baseUrl}#/home?sId=${token}`
          window.open(link, '_self')
        }

      }
    },
    //退出登录
    async loginOut() {
      await loginOutApi().then((res) => {
        console.log(res)
      })
      localStorage.clear()
      location.reload()
    }
  }
}
</script>
<style scoped lang="less">
.header {
  background: #fff;
  height: 68px;
  width: 100%;
  color: #ffffff;
  .topImg {
    position: relative;
    height: 68px;
    width: 100%;
    overflow: hidden;

    img {
      height: 68px;
      width: 100%;
    }

    p {
      position: absolute;
      top: 18px;
      left: 50%;
      transform: translate(-50%);
      font-weight: bold;
      font-size: 32px;
      color: #1890FF;
      line-height: 36px;
      letter-spacing: 10px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }

  .headerBox {
    margin-top: 0;
    // margin-left: 50%;
    // transform: translate(-50%);
    padding: 14px 24px;
    display: flex;
    justify-content: space-between;
    // width: 1200px;
    line-height: 30px;

    .headerLeft {
      margin-top: 2px;
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }

      h3 {
        margin: 0 40px 0 8px;
        flex-shrink: 0;
        width: 72px;
        cursor: pointer;
        font-size: 24px;
        color: rgba(0, 0, 0, 1);
      }

      ul {
        display: flex;
        flex-shrink: 0;

        li {
          font-size: 16px;
          margin-right: 20px;
          cursor: pointer;
          color: rgba(0, 0, 0, 0.80);
          font-weight: bold;
        }

        li:hover {
          color: rgba(24, 144, 255, 1);
        }
      }
    }

    .headerRight {
      display: flex;
      justify-content: flex-end;
      width: 800px;
      z-index: 999;

      .el-button {
        font-size: 16px;
        font-weight: bold;
        font-family: "SourceHanSancCN";
      }

      .searchStyle {
        margin-right: 16px;
        padding: 9px 16px;
        height: 40px;
        border: 0;
        border-radius: 4px;
        background-color: rgba(245, 246, 247, 1);
        color: rgba(0, 0, 0, 0.80);

        i {
          margin-right: 8px;
        }
      }

      .searchStyle:hover {
        background-color: rgba(245, 246, 247, .7);
        color: rgba(0, 0, 0, 0.60);
      }

      .headerSearch {
        margin-right: 28px;
        position: relative;
        width: 320px;
        height: 40px;
        border: 2px solid rgba(24, 144, 255, 1);
        border-radius: 4px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        overflow: hidden;

        /deep/ .searchBox {
          width: 233px;

          .el-input__inner {
            width: 233px;
          }

          .el-input__suffix-inner {
            cursor: pointer;
          }
        }

        /deep/ .el-input__inner {
          border: 0;
        }

        /deep/ .el-input-group__append {
          padding: 0 10px !important;
          border: 0;
        }

        .searchBtn {
          position: absolute;
          top: 12px;
          right: 2px;
          padding: 0 16px;
          color: rgba(0, 0, 0, 0.40);
          height: 16px;
          width: 83px;
          background-color: #fff;
          border: 0;
          border-left: 1px solid rgba(0, 0, 0, 0.10);
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          font-family: "SourceHanSancCN";
          line-height: 16px;

          i {
            margin-left: 8px;
            font-weight: bold;
          }
        }
      }

      .userBox {
        position: relative;
        margin-right: 24px;
        width: 40px;
        height: 40px;
        background-color: rgba(232, 244, 255, 1);
        border-radius: 50%;
        cursor: pointer;

        i {
          margin: 12px;
          display: inline-block;
          width: 16px;
          height: 16px;
          color: rgba(24, 144, 255, 1);
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .userDialog {
          position: absolute;
          top: 55px;
          left: -16px;
          width: 80px;
          height: 96px;
          background-color: #fff;
          border-radius: 8px;
          z-index: 101;

          p {
            height: 32px;
            font-size: 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.80);
            font-weight: bold;
          }

          p:nth-child(2) {
            color: rgba(0, 0, 0, 0.40)
          }

          p:hover {
            background-color: rgba(248, 249, 250, 1);
          }
        }
      }

      .userBox:hover {
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.07);
      }
    }
  }

  .classifyBox {
    position: absolute;
    top: 68px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: black;
    height: 68px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

    .leftClassify {
      width: 40px;
      height: 68px;
      background: rgba(255, 255, 255, 0.8);
      cursor: pointer;
      line-height: 68px;
      color: rgba(0, 0, 0, 0.40);
      font-size: 20px;

      i {
        padding-left: 15px;
      }
    }

    .leftClassify:hover {
      color: rgba(24, 144, 255, 1);
    }

    .centerClassify {
      flex: 1;
      height: 461px;
      overflow-x: hidden;
      white-space: nowrap;
      scroll-behavior: auto;

      ul {
        display: flex;
        justify-content: space-between;
        transition: transform 0.5s;

        li {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 8px 0;
          padding: 14px 14px 14px 17px;
          width: 160px;
          height: 52px;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          border-radius: 16px;
          cursor: pointer;

          img {
            margin-right: 8px;
            width: 24px;
            height: 24px;
          }

          p {
            width: 80px;
            font-size: 14px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.60);
          }

          i {
            font-size: 12px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.60);
          }

          .classifySubBox {
            position: absolute;
            top: 76px;
            left: 0;
            width: 410px;
            max-height: 380px;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            background-color: #fff;
            border-radius: 16px;
            cursor: auto;
            z-index: 100;

            >div {
              margin: 22px 5px 0 24px;
              max-height: 336px;
              overflow-y: auto;

              p {
                position: relative;
                margin-bottom: 20px;
                padding-top: 30px;
                display: flex;
                flex-wrap: wrap;
                width: 354px;

                i {
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  cursor: pointer;
                  font-size: 14px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.80);
                }

                i:hover {
                  color: rgba(24, 144, 255, 1);
                }

                span {
                  margin-right: 12px;
                  margin-bottom: 12px;
                  display: inline-block;
                  cursor: pointer;
                  font-size: 13px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.40);
                }

                span:hover {
                  color: rgba(24, 144, 255, 1);
                }
              }
            }

          }
        }

        li:hover {
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);

          p {
            color: rgba(24, 144, 255, 1);
          }

          i {
            color: rgba(24, 144, 255, 1);
          }
        }
      }
    }

    .smooth-scroll {
      scroll-behavior: smooth;
      /* 点击左右按钮时使用平滑滚动 */
    }

    .rightClassify {
      width: 40px;
      height: 68px;
      background: rgba(255, 255, 255, 0.8);
      cursor: pointer;
      line-height: 68px;
      color: rgba(0, 0, 0, 0.40);
      font-size: 20px;

      i {
        padding-right: 15px;
      }
    }

    .rightClassify:hover {
      color: rgba(24, 144, 255, 1);
    }
  }
}
</style>
